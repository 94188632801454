<template>
  <power-bi code="eyJrIjoiOTUzNDJlYTUtZThhYi00Njc0LThlNDktNDEwMzMwZDlhYmM5IiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9" />
</template>

<script>
import PowerBi from '@/views/pages/reports/components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
